import DocumentScannerRoundedIcon from '@mui/icons-material/DocumentScannerRounded'
import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import AppsIcon from '@mui/icons-material/Apps'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import AdUnitsIcon from '@mui/icons-material/AdUnits'
import SummarizeIcon from '@mui/icons-material/Summarize'
import AllInboxIcon from '@mui/icons-material/AllInbox'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import SettingsIcon from '@mui/icons-material/Settings'
import BarChartIcon from '@mui/icons-material/BarChart'

export default [
  {
    url: '/',
    name: 'Обзор',
    icon: <DashboardIcon />,
    iconLarge: (p) => <DashboardOutlinedIcon {...p} />,
  },
  {
    url: '/stats/installations',
    name: 'Установки',
    disabled: false,
    icon: <AppsIcon />,
  },
  {
    url: '/stats/data',
    name: 'Показатели',
    disabled: false,
    icon: <StackedBarChartIcon />,
  },
  { divider: true, label: 'Эхолоты' },
  {
    url: '/sonars/devices',
    name: 'Устройства',
    disabled: false,
    icon: <AdUnitsIcon />,
  },
  {
    url: '/sonars/stats',
    name: 'Статистика',
    disabled: false,
    icon: <BarChartIcon />,
  },
  {
    url: '/sonars/journals',
    name: 'Журналы',
    disabled: false,
    icon: <SummarizeIcon />,
  },
  { divider: true, label: 'Обновления' },
  {
    url: '/updates/apps',
    name: 'Приложения',
    disabled: false,
    icon: <DocumentScannerRoundedIcon />,
  },
  {
    url: '/updates/builds',
    name: 'Сборки',
    disabled: false,
    icon: <AllInboxIcon />,
  },
  { divider: true, label: 'Администрирование' },
  {
    url: '/admin/users',
    name: 'Пользователи',
    disabled: false,
    icon: <ManageAccountsIcon />,
  },
  {
    url: '/admin/settings',
    name: 'Настройки',
    disabled: false,
    icon: <SettingsIcon />,
  },
]
