import React from 'react'
import { Alert, Box, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

function LoadingAndAlertChart({
  error,
  loading,
  diagram = false,
  devices = false,
}) {
  return (
    <>
      {error && (
        <Alert variant="outlined" severity="error">
          {error}
        </Alert>
      )}

      {loading && (
        <Box
          display="flex"
          justifyContent={diagram ? 'flex-start' : 'center'}
          marginLeft={diagram ? '10rem' : 'none'}
          height={devices ? '16vh' : '30vh'}
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

LoadingAndAlertChart.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  diagram: PropTypes.bool,
  devices: PropTypes.bool,
}

export default LoadingAndAlertChart
