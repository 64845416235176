import React, { useState, useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import SonarsStateProvider from '../../app/Sonars/SonarsStateProvider'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import DiagramChart from '../Chart/SonarsChart/DiagramChart'
import AreaChartGeneral from '../Chart/SonarsChart/AreaChartGeneral'
import ToggleButtonIndicators from '../ToggleButton/ToggleButtonIndicators'
import ContentToggleButtonSonar from '../ToggleButton/ContentToggleButton/ContentToggleButtonSonar'
import ContentToggleButtonDiagram from '../ToggleButton/ContentToggleButton/ContentToggleButtonDiagram'
import {
  getStartDate,
  getEndDate,
} from '../../shared/ui/Components/ChartWrapper/datePeriodHelper'
import PropTypes from 'prop-types'

function ListChartsSonars({ model }) {
  const chartsConfig = [
    {
      logic: 'area',
      title: 'Общее время работы',
      content: ContentToggleButtonSonar,
      Component: AreaChartGeneral,
    },
    {
      logic: 'diagram',
      title: 'Общее время работы на прошивке',
      content: ContentToggleButtonDiagram,
      Component: DiagramChart,
    },
  ]

  return (
    <Stack spacing={1}>
      {chartsConfig.map(({ logic, title, content, Component }) => {
        const [startState, setStartState] = useState({
          period: 'quarter',
          detail: 'day',
        })

        const handleToggleChange = (name, value) => {
          setStartState((prev) => ({ ...prev, [name]: value }))
        }

        const query = useMemo(
          () => ({
            model: model,
            logic: logic,
            startDate: getStartDate(startState.period),
            endDate: getEndDate(),
          }),
          [logic, startState.period, model]
        )
        const componentProps =
          logic === 'area'
            ? {
                startDate: getStartDate(startState.period),
                endDate: getEndDate(),
                segment: startState.detail,
              }
            : { size: 250, radius: 100 }

        return (
          <Box key={logic}>
            <ContentPaper>
              <SonarsStateProvider query={query}>
                <Typography variant="h6" mb={2}>
                  {title}
                </Typography>
                <ToggleButtonIndicators
                  startState={startState}
                  buttonsGroupConfig={content}
                  setState={handleToggleChange}
                />
                <Component {...componentProps} />
              </SonarsStateProvider>
            </ContentPaper>
          </Box>
        )
      })}
    </Stack>
  )
}

ListChartsSonars.propTypes = {
  model: PropTypes.string.isRequired,
}

export default ListChartsSonars
