import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'

const formatOperatingTime = (seconds) => {
  if (seconds < 3600) return '< 1 час'
  const hours = Math.floor(seconds / 3600)
  const hourText = ['час', 'часа', 'часов']
  const form =
    hours % 10 === 1 && hours % 100 !== 11
      ? 0
      : hours % 10 >= 2 &&
        hours % 10 <= 4 &&
        (hours % 100 < 10 || hours % 100 >= 20)
      ? 1
      : 2
  return `${hours} ${hourText[form]}`
}

const CustomLegend = ({ data, sonars }) => (
  <Box display="flex" flexDirection="column" justifyContent="center">
    {data.map(({ color, label, count }, index) => (
      <Box key={index} display="flex" alignItems="center" ml={2}>
        <Box
          sx={{
            width: 12,
            height: 12,
            backgroundColor: color,
            display: 'inline-block',
            marginRight: '8px',
          }}
        />
        <Box display="flex" alignItems="center">
          <Typography variant="body2">{label}</Typography>
          {sonars && (
            <Typography ml={2} variant="body2" sx={{ color: '#8f8f8f' }}>
              {formatOperatingTime(count)}
            </Typography>
          )}
        </Box>
      </Box>
    ))}
  </Box>
)

CustomLegend.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ),
  sonars: PropTypes.bool,
}

export default CustomLegend
