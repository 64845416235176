import React, { useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import StackedChart from '../../Chart/SonarsChart/StackedChart'
import SonarsStateProvider from '../../../app/Sonars/SonarsStateProvider'
import { getEndDate } from '../../../shared/ui/Components/ChartWrapper/datePeriodHelper'
import ChartWrapperSonarsDevices from '../../../shared/ui/Components/ChartWrapper/ChartWrapperSonars/ChartWrapperSonarsDevices'

const SonarsStats = ({ data }) => {
  const queryParams = {
    segment: 'day',
    segmentSize: 24 * 60 * 60 * 1000,
    startDate: new Date(data.firstDate).getTime() / 1000,
  }

  const query = useMemo(
    () => ({
      logic: 'stacked',
      id: data.id,
      startDate: queryParams.startDate,
      endDate: getEndDate(),
    }),
    [data.id, queryParams.startDate]
  )
  return (
    <Stack direction="row" sx={{ my: 2, height: 420 }}>
      <Box direction="column" mr={10} spacing={1} minWidth={450}>
        <ChartWrapperSonarsDevices
          data={data.id}
          startDate={queryParams.startDate}
          endDate={getEndDate()}
          segment={queryParams.segment}
          segmentSize={queryParams.segmentSize}
        />
      </Box>
      <SonarsStateProvider query={query}>
        <StackedChart
          startDate={queryParams.startDate}
          endDate={getEndDate()}
          segment={queryParams.segment}
          segmentSize={queryParams.segmentSize}
        />
      </SonarsStateProvider>
    </Stack>
  )
}

SonarsStats.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SonarsStats
