import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SonarsStateContext from './SonarsStateContext'
import api from '../../shared/api'
import { useAuth } from '../OAuth/Provider'

function SonarsStateProvider({ children, query }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])

  const { getToken } = useAuth()

  useEffect(() => {
    setLoading(true)
    getToken().then((accessToken) =>
      api
        .get('/v1/sonars/states?' + api.objectToQuery(query), {
          authorization: accessToken,
        })
        .then((r) => {
          setData(r.states)
          setLoading(false)
          setLoaded(true)
        })
        .catch((e) => {
          setError(e.message)
        })
    )
  }, [query])

  const contextValue = useMemo(
    () => ({
      loaded,
      loading,
      error,
      data,
    }),
    [loaded, loading, error, data]
  )

  return (
    <SonarsStateContext.Provider value={contextValue}>
      {children}
    </SonarsStateContext.Provider>
  )
}

SonarsStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  query: PropTypes.shape({
    type: PropTypes.string,
  }),
}

export default SonarsStateProvider
