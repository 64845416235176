import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import LoadingAndAlertChart from '../../../shared/ui/Components/LoadingAndAlertChart.jsx'
import UserDeviceContext from '../../../app/Stats/UserDeviceContext.js'
import CustomLegend from '../../../shared/ui/Components/CustomLegend'
import { renderCustomizedLabel } from '../../../shared/ui/Components/CustomLabel'

const colors = [
  '#ffcc00',
  '#aad400',
  '#c90b0b',
  '#007bff',
  '#ff6f61',
  '#6a0dad',
]

function DiagramChart() {
  const { data, loading, error } = useContext(UserDeviceContext)

  const aggregatedData = data.map((item, index) => ({
    label: item.manufacturer || item.os || item.version || 'Unknown',
    count: item.count,
    color: colors[index % colors.length],
  }))

  return (
    <>
      <LoadingAndAlertChart error={error} loading={loading} diagram={true} />

      {!loading && aggregatedData.length > 0 && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <PieChart width={250} height={250}>
            <Pie
              data={aggregatedData}
              dataKey="count"
              nameKey="label"
              outerRadius={100}
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {aggregatedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value} устройств`} />
          </PieChart>
          <CustomLegend data={aggregatedData} />
        </Box>
      )}
    </>
  )
}

export default DiagramChart
