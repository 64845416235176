import React, { useContext, useRef } from 'react'
import { Box } from '@mui/material'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import SonarsStateContext from '../../../app/Sonars/SonarsStateContext.js'
import LoadingAndAlertChart from '../../../shared/ui/Components/LoadingAndAlertChart.jsx'
import CustomLegend from '../../../shared/ui/Components/CustomLegend'
import { renderCustomizedLabel } from '../../../shared/ui/Components/CustomLabel'
import PropTypes from 'prop-types'

const colors = ['#2e93c4', '#9ad400', '#d40000', '#ff8800']

function DiagramChartSonars({ size, radius, devices }) {
  const { data, loading, error } = useContext(SonarsStateContext)
  const ref = useRef(null)

  const aggregatedData = data.map((item, index) => ({
    label: item.firmware || null,
    count: item.sum,
    color: colors[index % colors.length],
  }))

  return (
    <>
      <LoadingAndAlertChart error={error} loading={loading} devices={devices} />
      {!loading && (
        <Box ref={ref} display="flex" flexDirection="row" alignItems="center">
          <PieChart width={size} height={size}>
            <Pie
              data={aggregatedData}
              dataKey="count"
              nameKey="label"
              outerRadius={radius}
              labelLine={false}
              label={devices ? false : renderCustomizedLabel}
            >
              {aggregatedData.map(({ color }, index) => (
                <Cell key={`cell-${index}`} fill={color} />
              ))}
            </Pie>
            {!devices && (
              <Tooltip
                formatter={(count) => `${Math.floor(count / 3600)} часов`}
              />
            )}
          </PieChart>
          <CustomLegend data={aggregatedData} sonars />
        </Box>
      )}
    </>
  )
}

DiagramChartSonars.propTypes = {
  size: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  devices: PropTypes.bool,
}

export default DiagramChartSonars
