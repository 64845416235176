import React, { useContext, useRef, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Line,
  CartesianGrid,
  Legend,
} from 'recharts'
import StatsContext from '../../../app/Stats/StatsContext.js'
import { useContainerDimensions } from '../../../shared/useContainerDimensions.jsx'
import PropTypes from 'prop-types'
import CustomTooltip from '../../../shared/ui/Components/CustomTooltip.jsx'
import {
  formatDateBySegment,
  groupDataBySegmentWithRanges,
  formatRanges,
} from '../dateUtils.jsx'
import LoadingAndAlertChart from '../../../shared/ui/Components/LoadingAndAlertChart.jsx'

const colorForRange = ['#ffcc00', '#aad400', '#c90b0b', '#0b9dc9', '#960bc9']

function SegmentedChart({ segment, chartType, startDate, endDate }) {
  const { data, loading, error } = useContext(StatsContext)

  const [segmentSize, setSegmentSize] = useState(7 * 24 * 60 * 60 * 1000)
  useEffect(() => {
    const sizes = {
      week: 7 * 24 * 60 * 60 * 1000,
      month: 30 * 24 * 60 * 60 * 1000,
    }
    setSegmentSize(sizes[segment] || sizes.week)
  }, [segment])

  const ref = useRef(null)
  const { width } = useContainerDimensions(ref)

  const filteredData = data
    .flatMap((rangeItem, rangeIndex) =>
      rangeItem.data.map((entry) => ({
        timestamp: entry.timestamp,
        [`range${rangeIndex + 1}`]: entry.count,
      }))
    )
    .reduce((acc, curr) => {
      const timestamp = curr.timestamp
      const existingEntry = acc.find(
        (entry) =>
          formatDateBySegment(entry.timestamp, segment) ===
          formatDateBySegment(timestamp, segment)
      )

      if (existingEntry) {
        Object.assign(existingEntry, curr)
      } else {
        acc.push(curr)
      }

      return acc
    }, [])
    .sort((a, b) => a.timestamp - b.timestamp)

  const gdata = groupDataBySegmentWithRanges(
    filteredData,
    segment,
    startDate,
    endDate,
    segmentSize
  )

  const formattedData = formatRanges(data)

  const renderLegend = () => (
    <Box display="flex" justifyContent="center" mt={2}>
      {formattedData.map((rangeItem, index) => (
        <Box key={index} display="flex" alignItems="center" mr={3}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: colorForRange[index % colorForRange.length],
              display: 'inline-block',
              marginRight: '8px',
            }}
          />
          {rangeItem.label}
        </Box>
      ))}
    </Box>
  )

  return (
    <Box ref={ref} sx={{ position: 'relative', minHeight: '300px' }}>
      <LoadingAndAlertChart loading={loading} error={error} />

      {!loading && filteredData.length > 0 && chartType === 'bar' ? (
        <BarChart
          width={width}
          height={300}
          data={gdata}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="timestamp" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {data.map((_, index) => (
            <Bar
              key={index}
              dataKey={`range${index + 1}`}
              fill={colorForRange[index % colorForRange.length]}
              background={{ fill: 'rgba(0,0,0,0.05)' }}
            />
          ))}
          <Legend content={renderLegend} />
        </BarChart>
      ) : (
        !loading &&
        filteredData.length > 0 && (
          <LineChart
            width={width}
            height={300}
            data={gdata}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="timestamp" />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Tooltip content={<CustomTooltip />} />
            {data.map((_, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={`range${index + 1}`}
                stroke={colorForRange[index % colorForRange.length]}
                connectNulls
              />
            ))}
            <Legend content={renderLegend} />
          </LineChart>
        )
      )}
    </Box>
  )
}

SegmentedChart.propTypes = {
  chartType: PropTypes.oneOf(['bar', 'line']).isRequired,
  segment: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired,
}

export default SegmentedChart
