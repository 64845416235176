import React, { useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import UsersDeviceProvider from '../../../../../app/Stats/UsersDeviceProvider'
import DiagramChart from '../../../../../widgets/Chart/StatsChart/DiagramChart'
import { getStartDate, getEndDate } from '../datePeriodHelper'
import ContentToggleButtonDiagram from '../../../../../widgets/ToggleButton/ContentToggleButton/ContentToggleButtonDiagram'
import ToggleButtonIndicators from '../../../../../widgets/ToggleButton/ToggleButtonIndicators'

function ChartWrapperUserDevice() {
  const chartsConfig = [
    { field: 'manufacturer', title: 'Производители устройств' },
    { field: 'os', title: 'Операционные системы' },
    { field: 'version', os: 'Android', title: 'Версии Android' },
    { field: 'version', os: 'iOS', title: 'Версии iOS' },
  ]

  return (
    <Grid container spacing={2}>
      {chartsConfig.map((chart) => {
        const [startState, setStartState] = useState({
          period: 'quarter',
          detail: 'day',
        })
        const handleToggleChange = (name, value) => {
          setStartState((prev) => ({ ...prev, [name]: value }))
        }

        const query = useMemo(
          () => ({
            field: chart.field,
            os: chart.os || '',
            startDate: getStartDate(startState.period),
            endDate: getEndDate(),
          }),
          [chart.field, chart.os, startState.period]
        )

        return (
          <Grid item xs={12} md={6} key={chart.title}>
            <UsersDeviceProvider query={query}>
              <Typography variant="h6" mb={2}>
                {chart.title}
              </Typography>
              <ToggleButtonIndicators
                startState={startState}
                buttonsGroupConfig={ContentToggleButtonDiagram}
                setState={handleToggleChange}
              />
              <DiagramChart />
            </UsersDeviceProvider>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ChartWrapperUserDevice
