import React, { useState, useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'
import SonarsStateContext from '../../../app/Sonars/SonarsStateContext'
import CustomTooltip from '../../../shared/ui/Components/CustomTooltip.jsx'
import { useContainerDimensions } from '../../../shared/useContainerDimensions.jsx'
import LoadingAndAlertChart from '../../../shared/ui/Components/LoadingAndAlertChart.jsx'
import { groupDataBySegmentWithRanges } from '../dateUtils'

function AreaChartGeneral({ endDate, startDate, segment }) {
  const { data, loading, error } = useContext(SonarsStateContext)
  const [chartData, setChartData] = useState([])
  const [segmentSize, setSegmentSize] = useState(24 * 60 * 60 * 1000)

  useEffect(() => {
    const sizes = {
      day: 24 * 60 * 60 * 1000,
      week: 7 * 24 * 60 * 60 * 1000,
      month: 30 * 24 * 60 * 60 * 1000,
    }
    setSegmentSize(sizes[segment] || sizes.day)
  }, [segment])

  const ref = useRef(null)
  const { width } = useContainerDimensions(ref)

  useEffect(() => {
    if (data) {
      const transformedData = data.map((item) => ({
        timestamp: item.timestamp,
        sum: Math.floor(item.sum / 3600),
      }))
      setChartData(transformedData)
    } else {
      setChartData([])
    }
  }, [data])

  const gdata = groupDataBySegmentWithRanges(
    chartData,
    segment,
    startDate,
    endDate,
    segmentSize
  )

  return (
    <Box ref={ref} sx={{ position: 'relative', minHeight: '300px' }} mt={2}>
      <LoadingAndAlertChart error={error} loading={loading} />
      {!loading && (
        <AreaChart
          width={width}
          height={300}
          data={gdata}
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="timestamp" />
          <YAxis />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <Tooltip content={<CustomTooltip type="linear" />} />
          <Area
            connectNulls
            type="monotone"
            dataKey="sum"
            stroke="#2e93c4"
            fill="#c3e2f1"
          />
        </AreaChart>
      )}
    </Box>
  )
}

AreaChartGeneral.propTypes = {
  endDate: PropTypes.number.isRequired,
  startDate: PropTypes.number.isRequired,
  segment: PropTypes.string,
  segmentSize: PropTypes.number,
}

export default AreaChartGeneral
