import React, { useContext, useState } from 'react'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import SonarsContext from '../../app/Sonars/SonarsContext'
import SonarsDialog from '../SonarsDialog/SonarsDialog'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import moment from 'moment'

const formatOperatingTime = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  let hourText = 'час'
  hours > 1 && hours < 5 ? (hourText = 'часа') : (hourText = 'часов')
  return `${hours} ${hourText}`
}

function SonarsTable() {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const { data, loading } = useContext(SonarsContext)

  const filteredData = data.map((row) => {
    return {
      ...row,
      operatingTime: formatOperatingTime(row.operatingTime),
      lastDate: moment(row.lastDate).format('D MMMM YYYY HH:mm:ss'),
      firstDateFormatted: moment(row.firstDate).format('D MMMM YYYY HH:mm:ss'),
    }
  })

  const handleDialogOpen = (row) => {
    setCurrentRow(row)
    setDialogOpen(true)
  }

  return (
    <>
      <SonarsDialog
        show={dialogOpen}
        onHide={() => setDialogOpen(false)}
        data={currentRow || {}}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Серийный номер</TableCell>
              <TableCell>Прошивка</TableCell>
              <TableCell align="right">Время работы </TableCell>
              <TableCell align="right">Подключение</TableCell>
              <TableCell align="right">Переподключение</TableCell>
              <TableCell>Данные от</TableCell>
              <TableCell align="center">
                {loading && <CircularProgress size={24} />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{
                    textAlign: 'center',
                    color: '#999',
                    height: 70,
                  }}
                >
                  Список пуст
                </TableCell>
              </TableRow>
            )}
            {filteredData.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>{row.serial}</TableCell>
                <TableCell>
                  {row.build === null ? (
                    <span style={{ color: '#8f8f8f' }}>
                      Нет данных о прошивке
                    </span>
                  ) : (
                    row.build.firmware
                  )}
                </TableCell>
                <TableCell align="center">{row.operatingTime}</TableCell>
                <TableCell align="center">{row.sessions}</TableCell>
                <TableCell align="center">{row.restoreConnection}</TableCell>
                <TableCell sx={{ color: '#8f8f8f' }}>{row.lastDate}</TableCell>
                <TableCell>
                  <Tooltip title="Полная информация об эхолоте">
                    <IconButton
                      onClick={() => handleDialogOpen(row)}
                      aria-label="stats"
                    >
                      <MoreHorizRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default SonarsTable
