import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Stack } from '@mui/material'
import AreaChartSonars from '../../../../../widgets/Chart/SonarsChart/AreaChartSonars'
import DiagramChart from '../../../../../widgets/Chart/SonarsChart/DiagramChart'
import SonarsStateProvider from '../../../../../app/Sonars/SonarsStateProvider'

const chartConfig = [
  {
    logic: 'diagram',
    title: 'Время работы на прошивке',
    Component: DiagramChart,
  },
  {
    logic: 'area',
    title: 'Время работы',
    Component: AreaChartSonars,
  },
]

function ChartWrapperSonarsDevices({
  data,
  startDate,
  endDate,
  segment,
  segmentSize,
}) {
  return (
    <Stack>
      {chartConfig.map(({ logic, title, Component }) => {
        const query = { logic, id: data, startDate, endDate }
        const componentProps =
          logic === 'area'
            ? { startDate, endDate, segment, segmentSize }
            : { size: 170, radius: 70, devices: true }

        return (
          <SonarsStateProvider key={logic} query={query}>
            <Typography variant="subtitle1" mt={2}>
              {title}
            </Typography>
            <Component {...componentProps} />
          </SonarsStateProvider>
        )
      })}
    </Stack>
  )
}

ChartWrapperSonarsDevices.propTypes = {
  data: PropTypes.string.isRequired,
  startDate: PropTypes.number.isRequired,
  endDate: PropTypes.number.isRequired,
  segment: PropTypes.string,
  segmentSize: PropTypes.number,
}

export default ChartWrapperSonarsDevices
