import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
} from '@mui/material'
import PropTypes from 'prop-types'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SonarsStats from './DialogPage/SonarsStats'
import CurrentStats from './DialogPage/CurrentStats'

const SonarsDialog = ({ show, onHide, data }) => {
  const [tab, setTab] = useState('currentStats')

  const handleChangeTab = (e, newTab) => {
    setTab(newTab)
  }

  const tabsPanel = [
    { name: 'Текущее состояние', value: 'currentStats' },
    { name: 'Статистика', value: 'sonarsStats' },
  ]

  if (!data) {
    return null
  }

  return (
    <Dialog open={show} onClose={onHide} maxWidth="lg">
      <DialogTitle
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 1 }}
      >
        <Box>
          {data.serial}{' '}
          <span style={{ opacity: 0.5, padding: '0 0.5em' }}>
            {data.transducer}
          </span>
        </Box>
        <IconButton aria-label="close" onClick={onHide}>
          <CloseRoundedIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 1000, p: 0 }}>
        <Stack sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="tabs"
            >
              {tabsPanel.map((t) => (
                <Tab key={t.value} label={t.name} value={t.value} />
              ))}
            </Tabs>
          </Box>
          {tab === 'currentStats' ? (
            <CurrentStats data={data} />
          ) : (
            <SonarsStats data={data} />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

SonarsDialog.propTypes = {
  data: PropTypes.shape({
    serial: PropTypes.string,
    transducer: PropTypes.string,
  }),
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default SonarsDialog
