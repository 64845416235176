import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SonarsContext from './SonarsContext'
import api from '../../shared/api'
import { useAuth } from '../OAuth/Provider'

function SonarsProvider({ children, query }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])

  const { getToken } = useAuth()

  const fetchSonars = () => {
    getToken()
      .then((accessToken) =>
        api
          .get('/v1/sonars/list?' + api.objectToQuery(query), {
            authorization: accessToken,
          })
          .then((r) => {
            setData(r.sonars)
            setLoading(false)
            setLoaded(true)
          })
          .catch((e) => {
            setError(e.message)
          })
      )
      .catch((e) => {
        setError(e.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSonars()
    setLoading(true)
  }, [query])

  const contextValue = useMemo(
    () => ({
      loaded,
      loading,
      error,
      data,
      refreshSonars: fetchSonars,
    }),
    [loaded, loading, error, data]
  )

  return (
    <SonarsContext.Provider value={contextValue}>
      {children}
    </SonarsContext.Provider>
  )
}

SonarsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  query: PropTypes.shape({
    type: PropTypes.string,
  }),
}

export default SonarsProvider
