import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  LinearProgress,
  IconButton,
  Tooltip,
  TextField,
} from '@mui/material'
import SonarsContext from '../../app/Sonars/SonarsContext'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'

function SelectSonarsDevice({
  selectedDevice,
  handleDeviceChange,
  searchedDevice,
  handleDeviceSearch,
  handleInputChange,
}) {
  const { data, loading } = useContext(SonarsContext)

  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '10px 20px',
    margin: '5px 0',
    borderRadius: '15px',
  }))

  const uniqueRows = data.filter(
    (row, index, self) =>
      index ===
      self.findIndex(
        (r) => r.model === row.model && r.transducer === row.transducer
      )
  )

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <Stack direction="row" spacing={2} sx={{ mb: 4 }} alignItems="center">
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="branch-select-label">Модель</InputLabel>
            <Select
              id="app-select"
              value={
                selectedDevice?.model || selectedDevice?.transducer
                  ? `${selectedDevice.model}|${selectedDevice.transducer}`
                  : ``
              }
              onChange={handleDeviceChange}
              label={'Модель'}
            >
              {uniqueRows.map((row, index) => (
                <StyledMenuItem
                  key={index}
                  value={`${row.model}|${row.transducer}`}
                >
                  {row.model}{' '}
                  <span style={{ opacity: 0.5, padding: '0 0.5em' }}>
                    {row.transducer}
                  </span>
                </StyledMenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="search-field"
            label="Поиск модели"
            name="searches"
            variant="outlined"
            placeholder="PPA000223"
            sx={{
              padding: '0px 0px',
              minWidth: 250,
              borderRadius: '15px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '15px',
              },
              background: '#fff',
            }}
            value={searchedDevice}
            onChange={(e) => handleInputChange(e)}
          />
          <Tooltip title="Найти">
            <IconButton
              aria-label="search"
              sx={{
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
                width: 50,
                height: 50,
              }}
              onClick={handleDeviceSearch}
            >
              <SearchRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </>
  )
}

SelectSonarsDevice.propTypes = {
  selectedDevice: PropTypes.shape({
    model: PropTypes.string,
    transducer: PropTypes.string,
  }),
  handleDeviceChange: PropTypes.func.isRequired,
  searchedDevice: PropTypes.string.isRequired,
  handleDeviceSearch: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
}

export default SelectSonarsDevice
