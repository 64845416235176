import React, { useContext, useRef } from 'react'
import { BarChart, Bar, XAxis } from 'recharts'
import PropTypes from 'prop-types'
import SonarsStateContext from '../../../app/Sonars/SonarsStateContext'
import { groupDataBySegmentWithRanges } from '../dateUtils'
import LoadingAndAlertChart from '../../../shared/ui/Components/LoadingAndAlertChart'
import { Box, Typography } from '@mui/material'

const StackedChart = ({ endDate, startDate, segment, segmentSize }) => {
  const { data, loading, error } = useContext(SonarsStateContext)
  const ref = useRef(null)

  const gdata = groupDataBySegmentWithRanges(
    data,
    segment,
    startDate,
    endDate,
    segmentSize
  )

  const totalDisconnect = gdata.reduce(
    (total, item) => total + (item.disconnect || 0),
    0
  )
  const totalConnect = gdata.reduce(
    (total, item) => total + (item.connect || 0),
    0
  )

  return (
    <Box ref={ref} position="relative" my={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1">Подключение и обрывы связи</Typography>
        <Typography variant="subtitle1">
          {totalConnect}/{totalDisconnect}
        </Typography>
      </Box>
      <LoadingAndAlertChart error={error} loading={loading} devices={true} />
      {!loading && (
        <BarChart
          width={450}
          height={380}
          data={gdata}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis axisLine={true} tick={false} label={''} />
          <Bar dataKey="connect" stackId="a" fill="#c3e2f1" />
          <Bar dataKey="disconnect" stackId="a" fill="#2e93c4" />
        </BarChart>
      )}
    </Box>
  )
}

StackedChart.propTypes = {
  endDate: PropTypes.number.isRequired,
  startDate: PropTypes.number.isRequired,
  segment: PropTypes.string.isRequired,
  segmentSize: PropTypes.number.isRequired,
}

export default StackedChart
