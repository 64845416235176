import { createContext } from 'react'

const SonarsContext = createContext({
  loading: false,
  loaded: false,
  data: [],
  error: null,
  refreshSonars: () => {},
})

export default SonarsContext
