import React, { useContext } from 'react'
import { Box, FormHelperText, LinearProgress, Typography } from '@mui/material'
import ChartWrapperIndicators from '../../shared/ui/Components/ChartWrapper/ChartWrapperStats/ChartWrapperIndicators'
import PropTypes from 'prop-types'
import TypesContext from '../../app/Administration/TypesContext'
import ContentPaper from '../../shared/ui/Components/ContentPaper'

function ListChartsIndicators({ view }) {
  const { data, loading } = useContext(TypesContext)
  return (
    <>
      {loading ? (
        <Box mt={5}>
          <LinearProgress />
        </Box>
      ) : (
        data.map((item) => (
          <Box key={item.code}>
            {item.valueView !== 'new' && (
              <ContentPaper my={3}>
                <Box mb={4}>
                  <Typography variant="h6">
                    {item.name}
                    <FormHelperText>{item.description}</FormHelperText>
                  </Typography>
                  <ChartWrapperIndicators
                    code={item.code}
                    group={view}
                    value={item.valueView}
                  />
                </Box>
              </ContentPaper>
            )}
          </Box>
        ))
      )}
    </>
  )
}

ListChartsIndicators.propTypes = {
  view: PropTypes.string.isRequired,
}

export default ListChartsIndicators
