import moment from 'moment'

export const formatDateBySegment = (timestamp, segment) => {
  try {
    const date = moment.unix(timestamp)
    const formats = {
      month: 'MMMM YYYY',
      week: 'YYYY-WW',
      default: 'DD-MM-YYYY',
    }
    return date.format(formats[segment] || formats.default)
  } catch (error) {
    console.error('Error formatting date:', error)
    return null
  }
}

const sumEntryValues = (entry, target, keys) => {
  keys.forEach((key) => {
    if (key !== 'timestamp') {
      if (!target[key]) target[key] = 0
      target[key] += entry[key]
    }
  })
}

export const groupDataBySegmentWithRanges = (
  data,
  segment = 'day',
  startDate,
  endDate,
  segmentSize = 24 * 60 * 60 * 1000
) => {
  try {
    const start = moment(startDate * 1000)
    const end = moment(endDate * 1000)

    if (!start.isValid() || !end.isValid()) {
      console.error('Invalid startDate or endDate')
      return []
    }

    const groupedData = {}
    const allKeys = new Set(data.flatMap((entry) => Object.keys(entry)))

    data.forEach((entry) => {
      const formattedDate = formatDateBySegment(entry.timestamp, segment)
      if (!formattedDate) return

      if (!groupedData[formattedDate]) {
        groupedData[formattedDate] = { timestamp: formattedDate }
      }
      sumEntryValues(entry, groupedData[formattedDate], Object.keys(entry))
    })

    const currentDate = start.clone()
    while (currentDate.isSameOrBefore(end)) {
      const formattedDate = formatDateBySegment(currentDate.unix(), segment)
      if (!groupedData[formattedDate]) {
        groupedData[formattedDate] = { timestamp: formattedDate }
        allKeys.forEach((key) => {
          if (key !== 'timestamp') {
            groupedData[formattedDate][key] = 0
          }
        })
      }
      currentDate.add(segmentSize, 'milliseconds')
    }

    return Object.values(groupedData).sort((a, b) => {
      const dateA = moment(
        a.timestamp,
        segment === 'month'
          ? 'MMMM YYYY'
          : segment === 'week'
          ? 'YYYY-WW'
          : 'DD-MM-YYYY'
      )
      const dateB = moment(
        b.timestamp,
        segment === 'month'
          ? 'MMMM YYYY'
          : segment === 'week'
          ? 'YYYY-WW'
          : 'DD-MM-YYYY'
      )
      return dateA.unix() - dateB.unix()
    })
  } catch (error) {
    console.error('Error grouping data:', error)
    return []
  }
}

export function formatRanges(data) {
  return data.map((item, index, array) => {
    const { min, max } = item.range

    const formatNumber = (value) => {
      if (value >= 1000000) {
        return `${(value / 1000000).toFixed(0)}м`
      }
      if (value >= 1000) {
        return `${(value / 1000).toFixed(0)}к`
      }
      return value
    }

    let label
    if (index === 0) {
      label = `до ${formatNumber(max)}`
    } else if (index === array.length - 1) {
      label = `более ${formatNumber(min)}`
    } else {
      label = `${formatNumber(min)} - ${formatNumber(max)}`
    }

    return {
      label,
      data: item.data,
      range: item.range,
    }
  })
}
