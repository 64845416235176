import React, { useEffect, useContext, useState, useMemo } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import SystemMenu from '../../shared/ui/SystemMenu'
import ListChartsSonars from '../../widgets/ListCharts/ListChartsSonars'
import ToggleGroup from '../../shared/ui/Components/ToggleGroup'
import PropTypes from 'prop-types'
import { LinearProgress, CircularProgress } from '@mui/material'
import SonarsProvider from '../../app/Sonars/SonarsProvider'
import SonarsContext from '../../app/Sonars/SonarsContext'

function SonarsGeneralToggleButtons({ onChanged }) {
  const { data, loading } = useContext(SonarsContext)
  const toggleButtons = useMemo(() => {
    return data.map((e) => ({
      key: e.model,
      name: e.model,
    }))
  }, [data])
  return loading ? (
    <LinearProgress />
  ) : (
    <>
      <ToggleGroup
        name="sonars"
        buttons={toggleButtons}
        onChanged={onChanged}
      />
    </>
  )
}

SonarsGeneralToggleButtons.propTypes = {
  onChanged: PropTypes.func,
}

function ModelsList({ model }) {
  const { data, loading } = useContext(SonarsContext)
  const sonarModel = model === '' && data.length > 0 ? data[0].model : model
  return loading || sonarModel === '' ? (
    <CircularProgress />
  ) : (
    <ListChartsSonars model={sonarModel} />
  )
}

ModelsList.propTypes = {
  model: PropTypes.string.isRequired,
}

function SonarsGeneral() {
  const { isAuthenticated, getToken, login } = useAuth()
  const [model, setModel] = useState('')

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  const handleModelChange = (name, v) => {
    if (v !== model) {
      setModel(v)
    }
  }
  const query = useMemo(
    () => ({
      unique: true,
    }),
    []
  )
  return (
    <SystemMenu>
      <h1>Общая статистика эхолотов</h1>
      <SonarsProvider query={query}>
        <SonarsGeneralToggleButtons onChanged={handleModelChange} />
        <ModelsList model={model} />
      </SonarsProvider>
    </SystemMenu>
  )
}
export default SonarsGeneral
