import React, { useEffect, useState, useCallback } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import { Box } from '@mui/material'
import SystemMenu from '../../shared/ui/SystemMenu'
import SonarsProvider from '../../app/Sonars/SonarsProvider'
import SonarsTable from '../../widgets/DataView/SonarsTable'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import SelectSonarsDevice from '../../widgets/Select/SelectSonarsDevice'

function Sonars() {
  const { isAuthenticated, getToken, login } = useAuth()
  const [TableSearchParams, setTableSearchParams] = useState({})
  const [searchedDevice, setSearchedDevice] = useState('')
  const [selectedDevice, setSelectedDevice] = useState({
    model: '',
    transducer: '',
  })

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  const handleDeviceChange = (event) => {
    if (!event.target.value) return
    const [model, transducer] = event.target.value.split('|')
    setSelectedDevice({ model, transducer })
  }

  const handleInputChange = useCallback((event) => {
    const processedValue = event.target.value.trim().toUpperCase()
    setSearchedDevice(processedValue)
  }, [])

  const handleSearchSubmit = () => {
    const processedParams = {
      serial: searchedDevice || '',
      model: selectedDevice.model || '',
    }
    setTableSearchParams(processedParams)
  }

  return (
    <SystemMenu>
      <h1>Устройства</h1>
      <SonarsProvider>
        <SelectSonarsDevice
          selectedDevice={selectedDevice}
          handleDeviceChange={handleDeviceChange}
          searchedDevice={searchedDevice}
          handleDeviceSearch={handleSearchSubmit}
          handleInputChange={handleInputChange}
        />
      </SonarsProvider>
      <SonarsProvider query={TableSearchParams}>
        <ContentPaper direction="column">
          <SonarsTable TableSearchParams={TableSearchParams} />
        </ContentPaper>
        <Box height={20} />
      </SonarsProvider>
    </SystemMenu>
  )
}
export default Sonars
