import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

const CurrentStats = ({ data }) => {
  const stats = [
    {
      name: 'Прошивка',
      value: data.build.firmware,
      publish: data.build.publish,
      date: data.build.creationDate,
    },
    { name: 'Время работы', value: data.operatingTime, suffix: 'часа' },
    { name: 'Подключение', value: data.sessions },
    { name: 'Переподключение', value: data.restoreConnection },
    {
      name: 'Первое получение данных',
      value: data.firstDateFormatted,
      muted: true,
    },
    {
      name: 'Последнее получение данных',
      value: data.lastDate,
      muted: true,
    },
  ]

  return (
    <Stack direction="column" sx={{ my: 2, height: 420 }}>
      {stats.map(({ name, value, muted, publish, date }) => (
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            maxWidth: 440,
            alignItems: 'center',
          }}
          key={name}
        >
          <Typography variant="subtitle1" width="240px" mr={4}>
            {name}
          </Typography>
          <Typography variant="body2" sx={muted ? { color: '#8f8f8f' } : {}}>
            {value}
          </Typography>
          {name === 'Прошивка' ? (
            <Box display="flex" alignItems="center">
              <Box ml={2} mr={1}>
                {publish === true ? (
                  <CheckCircleRoundedIcon color="success" />
                ) : (
                  <CancelRoundedIcon color="error" />
                )}
              </Box>
              {date ? (
                <Typography variant="body2" style={{ color: '#8f8f8f' }}>
                  {date}
                </Typography>
              ) : null}
            </Box>
          ) : null}
        </Box>
      ))}
    </Stack>
  )
}

CurrentStats.propTypes = {
  data: PropTypes.shape({
    build: PropTypes.shape({
      firmware: PropTypes.string,
      publish: PropTypes.bool,
      creationDate: PropTypes.string,
    }),
    operatingTime: PropTypes.string,
    sessions: PropTypes.number,
    restoreConnection: PropTypes.number,
    firstDateFormatted: PropTypes.string,
    lastDate: PropTypes.string,
  }).isRequired,
}

export default CurrentStats
