import { createContext } from 'react'

const SonarsStateContext = createContext({
  loading: false,
  loaded: false,
  data: [],
  error: null,
})

export default SonarsStateContext
